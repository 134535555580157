import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "60px 0",
	"sm-padding": "40px 0",
	"min-height": "300px",
	"sm-min-height": "auto",
	"background": "--color-light",
	"display": "flex",
	"flex-direction": "column"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"max-width": "850px",
			"color": "--secondary",
			"margin": "0px",
			"children": "Приєднуйтесь до РУХУ Адреналин Арена"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "8px 0px 32px",
			"font": "--headline2",
			"md-font": "--headline2",
			"color": "--dark",
			"max-width": "850px",
			"children": "Не чекайте – відчуйте гострі відчуття пейнтболу в Адреналин Арена вже сьогодні! Незалежно від того, чи хочете ви весело провести день з друзями, корпоративну командоутворення чи змагання, Адреналин Арена стане вашим головним місцем. Нехай починаються битви!"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Text {...override("text1")} />
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;